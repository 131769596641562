export interface ILanguage {
  name: string;
  code: string;
}

export const LANGUAGES: ILanguage[] = [
  { name: "English (US)", code: "en-US-Standard-D" },
  { name: "English (UK)", code: "en-GB-Standard-C" },
  { name: "Spanish", code: "es-ES-Standard-A" },
  { name: "Portuguese (Brazil)", code: "pt-BR-Standard-A" },
  { name: "Portuguese (Portugal)", code: "pt-PT-Standard-D" },
  { name: "Turkish", code: "tr-TR-Standard-D" },
];
