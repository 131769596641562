import moment from "moment";
import { CAPABILITY, NUMBER_FEATURE } from "../enums";

const SpecialFeatures = [
  {feature: NUMBER_FEATURE.VOICE_OTP, text: "OTP"},
  {feature: NUMBER_FEATURE.INTERNATIONAL_OTP, text: "OTP"},
  {feature: NUMBER_FEATURE.VOICE_MAIL, text: "VOICEMAIL"},
  {feature: NUMBER_FEATURE.WHOLESALE, text: "WHOLESALE"},
  {feature: NUMBER_FEATURE.RING_NO_ANSWER, text: "RING_NO_ANSWER"},
  {feature: NUMBER_FEATURE.SIP_ENABLED, text: "SIP"},
  {feature: NUMBER_FEATURE.IVR, text: "IVR"},
]

class SpecialLabel {
  text: string;
  className: string;

  constructor(init: any = {}) {
    Object.assign(this, init);
  }
}

export class NumberModel {
  uuid: string;
  name: string;
  number: string;
  country: string;
  capabilities: CAPABILITY[];
  issued_date: string;

  tts: string;
  features: NUMBER_FEATURE[];
  webhooks: string[];
  target_number: string;
  meta: {
    [key: string]: string;
  };
  meta_data: {
    [key: string]: string;
  };
  ga4: string[];
  whisper_file: string;
  call_tracking: string;
  record_call_file: string;
  keep_number_file: string;
  voicemail_lite_file: string;
  capture_digits_file: string;
  keywords: string[];
  billing_anniversary: string;
  last_called: string;
  route_uuid: string;
  third_party_integrations: string[]
  isNew: boolean;

  constructor(init: any = {}) {
    const data = {
      uuid: null,
      name: '',
      number: '',
      target_number: "",
      country: '',
      features: [],
      meta: {},
      meta_data: {},
      webhooks: [],
      tts: '',
      whisper_file: null,
      call_tracking: null,
      record_call_file: null,
      keep_number_file: null,
      voicemail_lite_file: null,
      capture_digits_file: null,
      keywords: [],
      issued_date: '',
      billing_anniversary: '',
      last_called: '',
      third_party_integrations: [],
      ga4: [],
      ...init,
    };
    Object.assign(this, data);
    this.isNew = this.isNewNumber();
  }

  isNewNumber() {
    return this.issued_date && moment(this.issued_date).isSameOrAfter(moment().subtract(1, 'hour'));
  }

  formatBillingAnniversary() {
    if (!this.billing_anniversary) {
      return '';
    }
    return moment(this.billing_anniversary).toNow(true);
  }

  formatLastCalled() {
    if (!this.last_called) {
      return '';
    }
    return moment(this.last_called).fromNow();
  }

  isParked() {
    return (this.features.includes(NUMBER_FEATURE.KEEP_NUMBER_TTS)
            || this.features.includes(NUMBER_FEATURE.KEEP_NUMBER_FILE)
            || this.features.includes(NUMBER_FEATURE.KEEP_NUMBER_EARLYMEDIA));
  }

  otpMode() {
    return (
      (this.features.includes(NUMBER_FEATURE.VOICE_OTP) && NUMBER_FEATURE.VOICE_OTP)
      || (this.features.includes(NUMBER_FEATURE.INTERNATIONAL_OTP) && NUMBER_FEATURE.INTERNATIONAL_OTP)
    )
  }

  specialLabel() {
    if (this.isParked()) {
      return new SpecialLabel({
        text: "PARKED",
        className: "h-7.5 flex-center text-primary bg-primary-lighter text-xs font-bold uppercase rounded px-2 ml-2"
      });
    }

    for (var obj of SpecialFeatures) {
      if (this.features.includes(obj.feature)) {
        return new SpecialLabel({
          text: obj.text,
          className: "h-7.5 flex-center text-blue bg-blue-lighter text-xs font-bold uppercase rounded px-2 ml-2"
        });
      }
    }
    return null;
  }
}
