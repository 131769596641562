import { Tooltip } from "@mui/material";
import classNames from "classnames";
import React, { FC, useEffect } from "react";
import { Card, CheckBox, Chip, Chips, WithTooltipIcon } from "../../../../components";
import { Form, FormControl, Input, Validators, useForm } from "../../../../components/ReactiveForm";
import { CheckCircleIcon, CloneIcon } from "../../../../components/icons";
import { ConfirmationModal } from "../../../../parts";
import { AuthService, ToastService, WhatsappService } from "../../../../services";
import { WA_WEBHOOK_TYPE, WA_WEBHOOK_TYPES, WEBHOOK_TYPE, WEBHOOK_TYPES, messageStatuses, chatLogStatuses } from "../../../../utils/enums";
import { copyToClipboard, formatPhoneNumber } from "../../../../utils/helpers";
import { WebhookModel, WhatsappAccountModel } from "../../../../utils/types";

export interface IWebhookFormInitParams {
  getFormData: () => any;
}

export interface IWebhookFormProps {
  webhook?: WebhookModel;
  confirmCancel?: boolean,
  setForm?: (params: IWebhookFormInitParams) => void;
  onClose?: () => void;
}

const statuses = {
  [WA_WEBHOOK_TYPE.CHATINTROLOG]: { status: chatLogStatuses, title: "Chat Intro Logs" },
  [WA_WEBHOOK_TYPE.MESSAGE]: { status: messageStatuses, title: "Messages" },
  [WA_WEBHOOK_TYPE.ALL]: { status: [], title: "All" },
}

export const selectAllSubscribedStatuses = (subscribed_statuses, wa_type: WA_WEBHOOK_TYPE) => {
  const status_types = statuses[wa_type].status.map(s => s.value);
  return [...subscribed_statuses, ...status_types];
}

export const deselectAllSubscribedStatuses = (subscribed_statuses, wa_type: WA_WEBHOOK_TYPE) => {
  const status_types = statuses[wa_type].status.map(s => s.value);
  return subscribed_statuses.filter(s => !status_types.includes(s));
}

export const WebhookForm: FC<IWebhookFormProps> = ({
  webhook,
  confirmCancel = false,
  setForm = () => { },
  onClose = (bypass?: boolean) => { },
}) => {
  //state
  const [form, formData] = useForm<{
    name: FormControl;
    url: FormControl;
    type: FormControl;
    webhook_type: FormControl;
    content_type: FormControl;
    whatsapp_number: FormControl;
    description: FormControl;
    subscribed_statuses: FormControl;
  }>({
    name: new FormControl('', [Validators.required()]),
    url: new FormControl('', [Validators.required(), Validators.url()]),
    type: new FormControl(null, [Validators.required()]),
    webhook_type: new FormControl(null, [Validators.required()]),
    content_type: new FormControl('application/json', [Validators.required()]),
    whatsapp_number: new FormControl('', [Validators.required()]),
    description: new FormControl(''),
    subscribed_statuses: new FormControl([]),
  });

  const [waAccounts, setWAAccounts] = React.useState<WhatsappAccountModel[]>([]);
  const [display, setDisplay] = React.useState<WA_WEBHOOK_TYPE[]>([]);

  React.useEffect(() => {
    if (formData.type !== WEBHOOK_TYPE.WHATSAPP) return
    WhatsappService.searchAccounts().then(res => {
      setWAAccounts(res.results.map(x => x.whatsapp_number))
    }).catch((err) => {
      ToastService.showHttpError(err, 'Updating webhook failed');
    })
  }, [formData.type])

  React.useEffect(() => {
    if (formData?.type !== WEBHOOK_TYPE.WHATSAPP) {
      setDisplay([]);
      return;
    }

    if (formData?.webhook_type === WA_WEBHOOK_TYPE.ALL) {
      setDisplay([WA_WEBHOOK_TYPE.CHATINTROLOG, WA_WEBHOOK_TYPE.MESSAGE]);
    } else {
      setDisplay([formData.webhook_type]);
    }
  }, [formData.type, formData.webhook_type])

  React.useEffect(() => {
    if (webhook) {
      form.patch({
        name: webhook.name,
        url: webhook.url,
        type: webhook.type,
        webhook_type: webhook.webhook_type,
        whatsapp_number: webhook.whatsapp_number,
        description: webhook.description,
        subscribed_statuses: webhook.subscribed_statuses,
      });
      form.controls.name.disable();
    }
  }, [webhook]);

  React.useEffect(() => {
    form.controls.whatsapp_number.disable(formData?.type !== WEBHOOK_TYPE.WHATSAPP);
    form.controls.content_type.disable(formData?.type !== WEBHOOK_TYPE.WHATSAPP);
    form.controls.webhook_type.disable(formData?.type !== WEBHOOK_TYPE.WHATSAPP);
    form.controls.subscribed_statuses.disable(formData?.type !== WEBHOOK_TYPE.WHATSAPP || formData?.webhook_type === WA_WEBHOOK_TYPE.ALL);
  }, [form, formData]);

  const getFormData = React.useCallback(() => {
    if (!form.validate()) return null;
    return form.getFormData();
  }, []);

  React.useEffect(() => {
    setForm({ getFormData });
  }, [formData]);


  //functions
  const onViewHelp = (redirect: string) => {
    AuthService.readme_login(redirect)
      .then((res) => {
        window.open(res.url)
      });
  };

  const onWaWebhookSelect = (wa_type: any) => {
    form.controls.webhook_type.patch(wa_type.value);
  }

  const selectAll = (wa_type: WA_WEBHOOK_TYPE) => {
    form.controls.subscribed_statuses.patch(
      selectAllSubscribedStatuses(formData.subscribed_statuses, wa_type)
    )
  }

  const deselectAll = (wa_type: WA_WEBHOOK_TYPE) => {
    form.controls.subscribed_statuses.patch(
      deselectAllSubscribedStatuses(formData.subscribed_statuses, wa_type)
    )
  }

  const onStatusSelect = (status: any) => {
    if (!formData) return;

    if (formData.subscribed_statuses.includes(status.value)) {
      form.controls.subscribed_statuses.patch(formData.subscribed_statuses.filter((s) => s !== status.value));
    } else {
      form.controls.subscribed_statuses.patch([...formData.subscribed_statuses, status.value]);
    }
  }

  return (
    <>
      <Form formGroup={form}>
        <Card
          className={"p-10"}
        >
          <div className="grid grid-cols-2 gap-6">
            <Input
              className={"col"}
              fullWidth
              control={form.controls.name}
              label="Name"
              labelClass="text-md font-semibold"
              placeholder="Give your webhook a name"
            />

            <Input
              fullWidth
              control={form.controls.url}
              label="URL"
              labelClass="text-md font-semibold"
              placeholder="Add the URL"
            />
          </div>
          {(formData?.type !== WEBHOOK_TYPE.WHATSAPP) && (
            <Input
              type="textarea"
              control={form.controls.description}
              fullWidth
              label="Description"
              labelClass="text-md font-semibold"
              containerClass="!pb-0"
              placeholder="Give the webhook a description"
              minRows={4}
            />
          )}
          <div className="text-13p text-blue-dark">
            <strong>Note:</strong> all webhook payloads are JSON encoded and sent with the <strong>“application/json”</strong> content type
          </div>
        </Card>
        <Card>

          {webhook?.hmac_secret && (
            <div className="mb-5">
              <div className="text-md font-semibold text-blue-dark">HMAC Secret</div>
              <Tooltip
                title={(
                  <div className="flex-center">
                    {webhook.hmac_secret}
                    <CloneIcon className="ml-2 cursor-pointer" size={14} color="primary" onClick={() => copyToClipboard(webhook.hmac_secret)} />
                  </div>
                )}
                arrow
                placement="top"
              >
                <span className="text-sm hover:text-blue truncate mt-1 cursor-pointer">{webhook.hmac_secret}</span>
              </Tooltip>
            </div>
          )}

          <div className="mb-5">
            <div className="text-md font-semibold text-blue-dark">What do you want your webhook to do?
              <button className="pl-1" onClick={() => onViewHelp("/reference/webhooks-2")}>
                <WithTooltipIcon tooltip={"See developer docs for more info"} placement="right" />
              </button>
            </div>
            <Chips className="mt-1">
              {WEBHOOK_TYPES.map((type, i) => (
                <Chip
                  data-cy={`webhook-type-${type.name}`}
                  key={i}
                  active={formData.type === type.value}
                  className={classNames(formData.type === type.value && "!shadow-md")}
                  onClick={() => form.controls.type.patch(type.value)}
                >
                  {type.name}
                </Chip>
              ))}
            </Chips>
          </div>
          {formData?.type === WEBHOOK_TYPE.WHATSAPP && (
            <div className={"grid grid-cols-2 gap-8"}>
              <Input
                type="autocomplete"
                control={form.controls.whatsapp_number}
                fullWidth
                options={waAccounts}
                getOptionLabel={(option) => formatPhoneNumber(option)}
                label="WA Account"
                labelClass="text-md font-semibold"
                disabled={!!webhook}
                renderOption={(props) => <li key={props.id} data-cy="whatsapp-number" {...props}>{(props as any).key}</li>}
              />

              <div>
                <p className="text-md font-semibold text-blue-dark">Please choose a WhatsApp webhook type</p>
                <Chips className="mt-2">
                  {WA_WEBHOOK_TYPES.map((type, i) => (
                    <Chip
                      data-cy={`wa-webhook-type-${type.name}`}
                      key={i}
                      active={formData.webhook_type === type.value}
                      className={classNames(formData.webhook_type === type.value && "!shadow-md")}
                      onClick={() => onWaWebhookSelect(type)}
                    >
                      {type.name}
                    </Chip>
                  ))}
                </Chips>
              </div>
            </div>
          )}
          {(formData?.type === WEBHOOK_TYPE.WHATSAPP && formData?.webhook_type) && (
            (formData?.webhook_type === WA_WEBHOOK_TYPE.ALL) ? (
              <p className="text-md font-semibold text-blue-dark">By selecting “All” you select both Chat Intro Logs and Message statuses</p>
            ) : (
              <p className="text-md font-semibold text-blue-dark my-4">Please select which statuses</p>
            )
          )}
          {display.map(type => (
            <>
              <div className="flex justify-between">
                <span className="text-md font-semibold text-blue-dark my-4">{statuses[type]?.title}</span>
                {formData?.webhook_type && formData.webhook_type !== WA_WEBHOOK_TYPE.ALL && (
                  <div className="grid grid-cols-2">
                    <button
                      className="hover:underline uppercase text-sm text-primary font-bold"
                      onClick={() => selectAll(type)}
                    >
                      Select All
                    </button>
                    <button
                      className="hover:underline uppercase text-sm text-primary font-bold"
                      onClick={() => deselectAll(type)}
                    >
                      Deselect All
                    </button>
                  </div>
                )}
              </div>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {statuses[type]?.status?.map(status => (
                  <div
                    data-cy={`webhook-${formData?.webhook_type}-status-${status.value}`}
                    className="flex flex-row py-2">
                    <CheckBox
                      className="px-2"
                      color="blue"
                      disabled={form.controls.subscribed_statuses.disabled}
                      onChange={() => onStatusSelect(status)}
                      value={formData?.webhook_type === WA_WEBHOOK_TYPE.ALL || formData?.subscribed_statuses?.includes(status.value)}
                    >
                      <div className="flex flex-col">
                        <span>{status.text}</span>
                        <span className="text-sm text-gray-400">{status.value}</span>
                      </div>
                    </CheckBox>
                  </div>
                ))}
              </div>
            </>
          ))}
        </Card>
      </Form>
      {
        confirmCancel && <ConfirmationModal
          title={"Confirm Cancel"}
          message={<p className="text-base">You have <span className="font-bold">unsaved changes.</span> Are you sure you would like to cancel and close this confirmation window?</p>}
          onClose={onClose}
        />
      }
    </>
  )
}
