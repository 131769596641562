import React, { FC, useEffect, useState } from "react";
import { uniq } from "lodash";
import classnames from "classnames";
import { Radio, RadioGroup } from "@mui/material";
import { Button, Card } from "../../../../components";
import { CloseCircleIcon, PlusCircleIcon } from "../../../../components/icons";
import { FormControl, FormGroup, Input, Validators, FormArray } from "../../../../components/ReactiveForm";
import { INumberEditForm } from "../index";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { NumberModel } from "../../../../utils/types";
import { NUMBER_FEATURE } from "../../../../utils/enums";

export interface ITargetNumbersProps {
  number: NumberModel;
  form: FormGroup<INumberEditForm>;
  formData: any;
  initialForm: any;
  setTouched?: any;
  onUpdate(data: any): void;
}

const callStrategies = [
  { text: 'Linear', value: NUMBER_FEATURE.HUNT_GROUP_LINEAR, description: `Numbers will be called in order. If the first number doesn't answer, the following numbers will be called in sequence` },
  { text: 'Random', value: NUMBER_FEATURE.HUNT_GROUP_RANDOM, description: `One of your Target Numbers will be called at random.` },
  { text: 'Ring All', value: NUMBER_FEATURE.HUNT_GROUP_RING_ALL, description: `All numbers will be called at the same time. Which ever number answers first, will make the other numbers stop ringing.` },
];

const TargetNumbers: FC<ITargetNumbersProps> = ({
  number,
  form,
  formData,
  initialForm,
  setTouched,
  onUpdate,
}) => {
  const [countryCode, setCountryCode] = useState<string>(number.country);

  useEffect(() => {
    form.controls.targets.clear();
    initialForm.targets = []

    const numbers = (number.target_number || "").split(",");
    numbers.forEach((number) => {
      if (number) {
        form.controls.targets.append(new FormControl(formatPhoneNumber(number)));
        initialForm.targets.push((formatPhoneNumber(number)))
      }
    });
    if (!form.controls.targets.controls.length) {
      form.controls.targets.append(new FormControl(''));
      initialForm.targets.push('')
    }

    callStrategies.forEach((item) => {
      if (number.features.includes(item.value)) {
        form.controls.targetsCallStrategy.patch(item.value);
      }
    });

    form.controls.targets.disable(false);

    return () => {
      form.controls.targets.disable();
    };
  }, [form, number]);

  useEffect(() => {
    if (formData?.features?.otpMode === NUMBER_FEATURE.INTERNATIONAL_OTP) {
      form.controls.targets.setValidators([Validators.required()]);
      setCountryCode(null);
    } else {
      form.controls.targets.setValidators([Validators.required(), Validators.phone(number.country)]);
      setCountryCode(number.country);
    }
  }, [formData?.features?.otpMode]);

  const onAddTargetNumber = (value?: string, index?: number) => {
    form.controls.targets.append(new FormControl(value), index);
  };

  const onRemoveTargetNumber = (control) => {
    form.controls.targets.remove(control);
    onSave();
  };

  const onSelectStrategy = (item) => {
    if (item.value !== formData.targetsCallStrategy) {
      form.controls.targetsCallStrategy.patch(item.value);
      initialForm.targetsCallStrategy = item.value
      onSave();
    }
  };

  const onSave = () => {
    if (!form.controls.targets.validate()) {
      setTouched(true);
      return;
    }

    const formData = form.getFormData();
    if (formData.targets[0] === undefined) {
      setTouched(true);
      return;
    }

    const formTargetNumbers = uniq(form.getFormData().targets.map((item) => item.replace(/\D/g, '')));
    const initialFormTargetNumbers = uniq(initialForm.targets.map((item) => item.replace(/\D/g, '')));

    const targetNumbers = uniq(formData.targets.map((item) => item.replace(/\D/g, '')));

    const validFeatures = callStrategies.map((item) => item.value);
    const features = (number.features || []).filter((item) => !validFeatures.includes(item));
    if (targetNumbers.length > 1) {
      features.push(formData.targetsCallStrategy);
    }
    onUpdate({
      target_number: targetNumbers.join(','),
      features,
    });
    setTouched(formTargetNumbers, initialFormTargetNumbers)

  };

  return (
    <Card size="lg">
      {form.controls.targets.controls.map((control, i) => (
        <div key={control.name} className="flex items-start flex-wrap mb-4">
          <div className="group max-w-130 flex items-start flex-grow">
            <Input
              type="phone"
              label={i ? `${i + 1}. Target Number` : 'Target Number'}
              tooltip={i === 0 && `The target number is the number that all calls from your Sudo Number, will be forwarded to`}
              tooltipPlacement="right"
              control={control}
              onBlur={onSave}
              countryCode={countryCode}
            />
            {i !== 0 && (
              <CloseCircleIcon
                className="opacity-0 group-hover:opacity-100 mt-6.5 ml-3 cursor-pointer transition-all"
                color="blue"
                size={32}
                onClick={() => onRemoveTargetNumber(control)}
              />
            )}
          </div>
          {i === formData.targets?.length - 1 && formData.targets?.length && (
            <div className="w-full md:w-auto flex-shrink-0">
              <Button
                data-cy="add-target-number"
                className={classnames(
                  'px-0 mt-4.5 ml-4',
                  formData.targets?.length < 5 ? 'text-blue' : 'text-secondary',
                )}
                leftIcon={<PlusCircleIcon />}
                disabled={formData.targets?.length >= 5}
                onClick={() => onAddTargetNumber()}
              >
                Add target number
              </Button>
            </div>
          )}
        </div>
      ))}
      {formData.targets?.length > 1 && (
        <div className="mt-4">
          <div className="font-semibold text-blue">How would you like your number(s) to ring?</div>
          <RadioGroup value={formData.targetsCallStrategy}>
            <div className="grid md:grid-cols-3 gap-4 mt-2">
              {callStrategies.map((item, i) => (
                <div
                  data-cy="target-call-strategy"
                  key={i}
                  className={classnames(
                    'flex items-start border rounded px-2 pt-1 pb-2 cursor-pointer',
                    item.value === formData.targetsCallStrategy ? 'border-blue' : 'text-gray-b4',
                  )}
                  onClick={() => onSelectStrategy(item)}
                >
                  <Radio size="small" value={item.value} />
                  <div className="ml-1 mt-1.5">
                    <div className="font-semibold">{item.text}</div>
                    <p className="text-xs">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </RadioGroup>
        </div>
      )}
    </Card>
  );
};

export default TargetNumbers;
